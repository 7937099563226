import {WorkSheetDetails as ApiWorkSheetDetails} from "../../../../../../API/workSheets/types";
import {OdometerReadingWithBusId, ReadingDisplayType} from "../../../../../../API/bus/types";
import {GridColDef, GridRenderCellParams, GridValueFormatterParams} from "@mui/x-data-grid";
import {decimalToDisplayStr, decimalToFixedDisplayStr} from "../../../../../../utils/utils";
import {Alert, Box, Typography} from "@mui/material";
import {Add, Check, WarningAmberRounded} from "@mui/icons-material";
import {getFuelTranslation} from "../../../../../../utils/enumTranslations";
import Button from "../../../../../../components/Button/Button";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../../../components/DataGrid/DataGrid";
import React from "react";
import {formatTimeAtDate, getDisplayTimeRangeFromDateTimeStr} from "../../../../../../utils/dateUtils";
import {ResourceType} from "../../../../../../API/workGroup/types";
import {BusUsageWithDistance} from "../../../../dashboard/DriverDashboard/types";
import ActionIcon from "../../../../../../components/Icon/ActionIcon";
import StaticIcon from "../../../../../../components/Icon/StaticIcon";
import {useAuth} from "../../../../../../contexts/AuthContext";
import {Permission} from "../../../../../../types";
import Tooltip from "../../../../../../components/Tooltip/Tooltip";

const TOOLTIP_CONTENT = (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, p: 1}}>
        <Box>
            Ühe bussikasutusena tuvastatakse päeva jooksul tehtud töid, mis on teostatud järjest ühe sama juhi ja ühe sama bussiga.
            Bussikasutuses võib päeva jooksul esineda piiramata ajaga pause, aga kui selle aja sees esineb bussil ilma sama juhita töid, siis bussikasutus katkeb.
        </Box>
        <Box>
            Bussikasutuse odomeetri algnäit tuvastatakse esimese selle bussiga tehtud töö algusaja alusel.
            Leitakse bussikasutuse algusajale lähim näit, mille aeg on vähem kui 15 minutit varasem või vähem kui 15 minutit hilisem.
        </Box>
        <Box>
            Sarnaselt tuvastatakse odomeetri lõppnäitu viimase bussiga tehtud töö lõpuaja alusel, mis tohib tegevuse lõpuajast erinada samuti vähem kui 15 minutit.
        </Box>
    </Box>
);

export interface OdometerReadingRow extends OdometerReadingWithBusId {
    displayType: ReadingDisplayType;
    licencePlateNumber?: string;
    expectedTotalDistance?: number;
    distanceDiff?: number;
    busUsage?: BusUsageWithDistance;
}

interface OdometerReadingsProps {
    workSheet: ApiWorkSheetDetails,
    secondaryWorkSheets: ApiWorkSheetDetails[],
    odometerReadingRows: OdometerReadingRow[];
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleAddOdometerReading: () => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
    isForbidden: boolean;
}

const OdometerReadings = ({
    workSheet,
    secondaryWorkSheets,
    odometerReadingRows,
    handleEditOdometerReading,
    handleAddOdometerReading,
    handleDeleteOdometerReading,
    isForbidden,
}: OdometerReadingsProps) => {
    const {hasPermission} = useAuth();

    const getBusUsageTimes = (busUsage?: BusUsageWithDistance) => {
        const busUsageTimes = busUsage ?getDisplayTimeRangeFromDateTimeStr(busUsage.startDateTime, busUsage.endDateTime, workSheet.startDate) : undefined;

        return busUsageTimes ? (
            <Tooltip title={`Algnäitu ja lõppnäitu arvestatakse bussikasutuse alusel, mis toimub kell ${busUsageTimes}`}>
                <Typography color="text.secondary" variant="body2" pl={0.5}>{busUsageTimes}</Typography>
            </Tooltip>
            ) : '';
    };

    const readingColumns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'displayType',
            headerName: 'Tüüp',
            sortable: false,
            minWidth: 100,
            maxWidth: 110,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'licencePlateNumber',
            headerName: 'Buss',
            sortable: false,
            minWidth: 200,
            maxWidth: 220,
            renderCell: params => <>{params.value} {getBusUsageTimes(params.row.busUsage)}</>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'dateTime',
            headerName: 'Aeg',
            sortable: false,
            minWidth: 90,
            maxWidth: 100,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'reading',
            headerName: 'Näit',
            sortable: false,
            minWidth: 100,
            maxWidth: 110,
            valueFormatter: (params: GridValueFormatterParams) => decimalToDisplayStr(params.value) + ' km'
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'expectedTotalDistance',
            headerName: 'Planeeritud läbisõit',
            sortable: false,
            minWidth: 130,
            maxWidth: 170,
            valueFormatter: (params: GridValueFormatterParams) => decimalToFixedDisplayStr(params.value) + (params.value ? ' km' : '')
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'distanceDiff',
            headerName: 'Läbisõidu erinevus',
            sortable: false,
            minWidth: 130,
            maxWidth: 170,
            renderCell: (params: GridRenderCellParams) =>
                params.row.displayType === 'Lõppnäit' ?
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {params.value !== undefined && Math.abs(params.value) < 2 ?
                            <Check color="success" fontSize="small" sx={{pr: 1}}/>
                            :
                            <WarningAmberRounded color="warning" fontSize="small" sx={{pr: 1}}/>
                        }
                        {params.value === undefined ?
                            <Typography variant="body2" fontStyle="italic">algnäit puudub</Typography>
                            :
                            decimalToFixedDisplayStr(Math.abs(params.value)) + ' km ' + (params.value === 0 ? '' : params.value < 0 ? 'väiksem' : 'suurem')
                        }
                    </Box>
                    :
                    <></>
            },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'fuelType',
            headerName: 'Kütuse liik',
            sortable: false,
            minWidth: 100,
            maxWidth: 110,
            valueFormatter: (params) => getFuelTranslation(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'fuelAmount',
            headerName: 'Tangitud kogus',
            sortable: false,
            minWidth: 110,
            maxWidth: 140,
            valueFormatter: (params) => decimalToFixedDisplayStr(params.value, 2)
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 80,
            renderCell: (params: GridRenderCellParams) =>(
                !hasPermission(Permission.UpdateWorkSchedules) ? <></> :
                    <>
                        <ActionIcon type="EDIT" id={params.row.id} alignTooltipArrowRight handleClick={() => handleEditOdometerReading(params.row)} />
                        <ActionIcon type="DELETE" id={params.row.id} alignTooltipArrowRight handleClick={() => handleDeleteOdometerReading(params.row)} />
                    </>
            )
        },
    ];

    const showOdometerReadings = workSheet.resourceType === ResourceType.DRIVER && secondaryWorkSheets.length > 0;

    if (workSheet.resourceType === ResourceType.DRIVER && isForbidden) {
        return <Alert severity="warning" sx={{my: 2}}>Odomeetri näite ei saa kuvada, kuna puuduvad õigused teise piirkonna bussi sõidulehe vaatamiseks.</Alert>
    }

    return (showOdometerReadings &&
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: {xs: 'start', sm: 'end'},
                flexDirection: {xs: 'column', sm: 'row'},
                pt: 3,
                pb: 1
            }}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h6">Odomeetri näidud ja tankimised</Typography>
                    <Tooltip title={TOOLTIP_CONTENT}>
                        <Box>
                            <StaticIcon type="INFO" size="medium" />
                        </Box>
					</Tooltip>
                </Box>
                {hasPermission(Permission.UpdateOdometerReadings) && <Button text="Lisa näit" startIcon={<Add/>} onClick={handleAddOdometerReading} />}
            </Box>
            <DataGrid rows={odometerReadingRows} columns={readingColumns}/>
        </Box>
    );
};

export default OdometerReadings;
